import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Empresas } from "../pages/empresas";
import { Page404 } from "../pages/page404";
import FormSection from "../pages/empresas/sections/formSection";

export const Rota = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/home" component={Empresas} />
        <Route exact path="/" >
          <Redirect to="/home"/>
        </Route >
        <Route exact path="/login" >
          <Redirect to="/home"/>
        </Route >

        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
};
