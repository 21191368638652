import React from 'react'
import MainText from '../../../../components/elements/MainText'

import { Container, Content, Image, TextWrapper } from './styles'

import BillingImage from '../../../../assets/imagens/SolutionsImage.png'

const Solutions = () => {
	return (
		<Container>
			<MainText
				firstText='Você gostaria de transformar suas ideias em'
				pinkText={'soluções'}
				secondText='?'
			/>
			<Content>
				<Image
					src={BillingImage}
					alt='BillingImage'
				/>
				<TextWrapper>
					<p>
						Design Thinking é uma abordagem interdisciplinar que ajuda a
						transformar ideias em soluções inovadoras para problemas complexos.
						Com a empatia como ponto de partida, o Design Thinking envolve
						colaboração entre diversas áreas de conhecimento para encontrar
						soluções criativas e centradas no usuário.
					</p>
					<p>
						Ao prototipar e testar as soluções com os usuários finais, o Design
						Thinking ajuda a validar e aprimorar as ideias, transformando-as em
						soluções viáveis e eficazes. Seja em produtos, serviços ou
						processos, o Design Thinking é uma abordagem poderosa para criar
						soluções que atendam às necessidades do usuário e que agreguem valor
						ao negócio.
					</p>
				</TextWrapper>
			</Content>
		</Container>
	)
}

export default Solutions
