import React from 'react'

import {
	Container,
	Border,
	Content,
	TextWrapper,
	Title,
	Image,
	ButtonText,
	ButtonWrapper,
} from './styles'

import GrowFastImage from '../../../../assets/imagens/OfferImage.png'
import { Link } from 'react-router-dom'

const Offer = () => {
	return (
		<Container>
			<Border>
				<Content>
					<TextWrapper>
						<Title>O que a DUXCOD3 oferece?</Title>
						<p>
							A DUXCOD3 oferece uma abordagem centrada no ser humano para
							resolver problemas e criar soluções inovadoras. Nos concentramos
							em compreender as necessidades, desejos e comportamentos dos
							usuários para criar soluções que atendam às suas necessidades de
							maneira eficaz e eficiente, vamos te ajudar a aplicar a
							metodologia em seus processos de inovação, desde a pesquisa de
							mercado até o desenvolvimento de protótipos e testes de validação,
							feitas através de pesquisa e análise de mercado, tudo isso junto a
							criação de personas e mapas da jornada do cliente, teste de
							usabilidade, dentre outros, de forma a levar seu projeto a um
							próximo nível!
						</p>
						<Button />
					</TextWrapper>
					<Image src={GrowFastImage} />
				</Content>
			</Border>
		</Container>
	)
}

const Button = () => {
  const handleClick = () => {
    const formComponent = document.getElementById("form");
    if (formComponent) {
      formComponent.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ButtonWrapper onClick={handleClick}>
      <ButtonText>ENTRAR EM CONTATO</ButtonText>
    </ButtonWrapper>
  );
};

export default Offer
