import { useContext, useEffect } from 'react'
import { Footer } from '../../components/components/footer'
import { Navbar } from '../../components/components/navbar'
import { Termos } from '../../components/components/termos'
import Context from '../../context'
import { Acompanhe } from './sections/acompanhe'
import { Parceiros } from './sections/parceiros'
import { StylesContainer } from './style'
import FloatWhatsapp from '../../components/elements/FloatWhatsapp'
import Initial from './sections/initial'
import Solutions from './sections/Solutions'
import Offer from './sections/Offer'
import Potential from './sections/Potential'
import FormSection from './sections/formSection'
import HowItWorks from './sections/HowItWorks'
import Process from './sections/Process'

export const Empresas = () => {
	const { isDarkTheme, setIsDarkTheme, onTermos } = useContext(Context)

	useEffect(() => {
		setIsDarkTheme(true)
	}, [isDarkTheme, setIsDarkTheme])

	return (
		<StylesContainer>
			{onTermos && <Termos />}
			<FloatWhatsapp />
			<Navbar />
			<Initial />
			<Solutions />
			<Offer />
			<Process />
      <HowItWorks />
			<Potential />
			<FormSection />
			<Parceiros />
			<Acompanhe />
			<Footer />
		</StylesContainer>
	)
}
