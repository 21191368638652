import styled from "styled-components";

export const ParceirosContainer = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    letter-spacing: 8px;
  }

  .companies, .companies2 {
    max-width: 800px;
    margin-top: 70px;
    margin-bottom: 100px;
  }
  .companies2 {
    max-width: 550px;
  }
  .companies:nth-child(2) {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    .companies {
      max-width: 500px;
    }
    .companies2 {
      max-width: 400px;
    }
  }
  @media (max-width: 582px) {
    margin-bottom: 60px;
    .companies {
      max-width: 94%;
    }
    .companies2 {
      max-width: 70%;
    }
  }
  @media (max-width: 450px) {
    margin-bottom: 60px;
    padding: 20px;
  }
`;
