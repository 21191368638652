import styled from "styled-components";
import { fontsType } from "../../../styles/fonts";

interface Props {
  children: string | React.ReactNode;
  color?: "white" | "black" | "pink";
}

export const Subtitle = ({ children, color }: Props) => {
  return <H2 color={color}>{children}</H2>;
};

const convertColor = (color: any) => {
  switch (color) {
    case "white":
      return "#fff";
    case "black":
      return "#000";
    case "pink":
      return "#f653ff";
    default:
      return "#000";
  }
};

const H2 = styled.h2`
  font-family: ${fontsType.rubikRegular};
  color: ${(props) => convertColor(props.color) || "#000"};
  margin: 0;
  font-size: 18px;
  text-align: center;
`;
