import whatsapp from "../../../assets/imagens/whatsapp.png";
import { FloatButton } from "./styles";

export default function FloatWhatsapp() {
  return (
    <FloatButton>

    </FloatButton>
  );
}
