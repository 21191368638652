import React from 'react'
import CardHowItWorks from '../../../../components/components/CardHowItWorks'
import MainText from '../../../../components/elements/MainText'

import { Container, CardsWrapper } from './styles'

import bulbIcon from '../../../../assets/imagens/BulbIcon.svg'
import WireframeIcon from '../../../../assets/imagens/wireframeIcon.svg'
import NavigationIcon from '../../../../assets/imagens/NavigationIcon.svg'
import RoadmapIcon from '../../../../assets/imagens/RoadmapIcon.svg'
import UserIcon from '../../../../assets/imagens/UserIcon.svg'

const HowItWorks = () => {
	const cards = [
		{
			id: 1,
			title: 'Estruturação de ideia',
			text: 'Utilização de técnicas de DT e DS focadas em apresentar o desenho funcional do produto ou ideia.',
			icon: bulbIcon,
		},
		{
			id: 2,
			title: 'Prototipação com wireframe',
			text: 'Apresentação de campos e Arquitetura de Dados e informação.',
			icon: WireframeIcon,
		},
		{
			id: 3,
			title: 'Protótipo navegável',
			text: 'Desenvolvimento das telas navegáveis com identidade visual.',
			icon: NavigationIcon,
		},
		{
			id: 4,
			title: 'Mapeamento de Roadmap',
			text: 'Criação de Backlog inicial da ideia/produto, apresentando os épicos e suas priorizações organizando o plano de projeto.',
			icon: RoadmapIcon,
		},
		{
			id: 5,
			title: 'Experiência do Usuário',
			text: 'Desenho das Jornadas dentro da experiência do usuário (UX) e design da interface (UI).',
			icon: UserIcon,
		},
	]

	return (
		<Container>
			<MainText
				firstText='Como'
				pinkText='funciona'
				secondText='?'
			/>
			<CardsWrapper>
				{cards.map((card) => (
					<CardHowItWorks
						key={card.id}
						icon={card.icon}
						title={card.title}
						text={card.text}
					/>
				))}
			</CardsWrapper>
		</Container>
	)
}

export default HowItWorks
