import styled from 'styled-components'
import { fontsType } from '../../../styles/fonts'

export const Container = styled.div`
	padding: 2px;

	background: linear-gradient(180deg, #f653ff 0%, #6653ff 100%);

	width: 250px;
	height: 400px;
	border-radius: 15px;

	@media (min-width: 1024px) {
		padding: 3px;
		width: 300px;
		height: 450px;
	}
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	padding: 40px 12px;

	align-items: center;
	border-radius: 15px;
	background: #060234;

	text-align: center;
	color: #fff;

	@media (min-width: 1024px) {
		padding: 60px 24px;
	}
`

export const Number = styled.div`
	background: #251D64;
	padding: 14px 12px;
	border-radius: 8px;
	filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.161));

	color: #7053FF;
	font-family: ${fontsType.ErbaumMedium};
	font-size: x-large;
	margin-bottom: 24px;

	@media (min-width: 1024px) {
		font-size: xx-large;
	}
`

export const Title = styled.span`
	font-family: ${fontsType.rubikRegular};
	font-size: larger;

	@media (min-width: 1024px) {
		font-size: x-large;
	}
`

export const Text = styled.span`
	font-family: ${fontsType.rubikLight};
	font-size: small;

	@media (min-width: 1024px) {
		font-size: 14px;
	}
`
