import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

export const Container = styled.div`
  margin-top: 100px;
`

export const Root = styled(Swiper)`
  width: 90%;
  height: 350px;
  margin: 30px auto;

  @media (min-width: 768px) {
    height: 450px;
  }

  @media (min-width: 1024px) {
    height: 550px;
  }

  @media (min-width: 1440px) {
    height: 650px;
  }
`
export const Slide = styled(SwiperSlide)`

.swiper-slide {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
  
`
