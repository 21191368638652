import { useContext } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import logoSquare from "../../../assets/logoDuxcod3Quadrada.png";
import Context from "../../../context";
import { Logo, Nav, NavbarListMenu } from "./styled";

interface LiProps {
  y: number;
  children: string;
}
interface SubLiProps {
  href: string;
  children: string;
}
export const Navbar = () => {
  const { isDarkTheme } = useContext(Context);
  const scrollTo = (y: number) => {
    window.scrollTo({ top: window.screen.height * y, behavior: "smooth" });
  };

  const menuItens = [
    { y: 1.1, children: "Quem somos" },
    { y: 1.9, children: "O que fazemos" },
    { y: 3.2, children: "Como fazemos" }
  ];
  const subMenu = [
    {
      href: "https://www.marketing.duxcod3.com/",
      children: "Marketing Digital"
    },
    { href: "https://www.ui.duxcod3.com/", children: "Design Thinking" },
    { href: "https://www.talentos.duxcod3.com/", children: "Talentos" }
  ];

  const Li = ({ y, children }: LiProps) => {
    return (
      <li className="nav-item">
        <span className="nav-link" onClick={() => scrollTo(y)}>
          {children}
        </span>
      </li>
    );
  };
  const SubLi = ({ href, children }: SubLiProps) => {
    return (
      <li className="nav-item-item">
        <a
          className="nav-link-item"
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      </li>
    );
  };
  return (
    <Nav>
        <a
          href="https://www.duxcod3.com"
          target="_blank"
          rel="noreferrer"
        >
          <Logo
            src={logoSquare}
            alt="logo gis name"
          />
        </a>
    </Nav>
  );
};
