import styled from "styled-components";
import { fontsType } from "../../../styles/fonts";
import { Theme } from "../../../theme";

interface DarkProps {
  isDarkTheme?: boolean;
}

export const Nav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  @media (max-width: 767px) {
		align-items: center;
	}
`;

export const Logo = styled.img`
	margin-left: 3rem;
	width: 20rem;
	height: auto;
	z-index: 101;

	@media (max-width: 767px) {
		margin-left: 0px;
		width: 18rem;
	}

	@media (max-width: 300px) {
		margin-left: 0px;
		width: 13rem;
	}
`

export const NavbarListMenu = styled.ul<DarkProps>`
  li {
    position: relative;
    margin-left: 10px;
    text-decoration: none;
    list-style-type: none;
    margin-bottom: 10px;
    a {
      color: ${(props) =>
        (props.isDarkTheme ? Theme.dark : Theme.light).text} !important;
      text-decoration: none;
      list-style-type: none;
      font-family: ${fontsType.rubikLight};
      position: relative;
      cursor: pointer;
    }
    span {
      color: ${(props) =>
        (props.isDarkTheme ? Theme.dark : Theme.light).text} !important;
      text-decoration: none;
      list-style-type: none;
      font-family: ${fontsType.rubikLight};
      position: relative;
      cursor: pointer;
    }
  }

  .navbar-nav-subItem {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    position: relative;
  }

  .navbar-div {
    display: none;
  }

  .navbar-nav-subItem nav {
    display: none;
  }
  .navbar-nav-subItem:hover nav {
    display: flex;
  }
  .navbar-div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: "linear-gradient(to bottom, #261E65, #060234)";
    padding: 20px;
    width: 200px;
    position: absolute;
    top: 40px;
    left: 15%;
  }

  li::after {
    content: "";
    display: block;
    position: absolute;
    width: 0%;
    height: 3px;
    background: #6653ff;
    bottom: -8px;
    margin: auto 50%;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
  }

  li:hover {
    span {
      font-family: ${fontsType.rubikBold};
    }

    &::after {
      background-color: #6653ff;
      width: 80%;
      margin: auto 10%;
    }
  }
  a:hover {
    font-family: ${fontsType.rubikBold};
  }
  @media (max-width: 450px) {
    .navbar-nav-subItem {
      flex-direction: row;
    }
    .navbar-div {
    top: -20px;
    left: 20%;
  }
   li:hover::after {
  width: 0 ;
}
 
  }
`;

export const NavbarLoginContainer = styled.div<DarkProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`;
