import { useEffect, useRef } from 'react'

import {
	Container,
	Video,
	LeftSide,
	Text,
	SubText,
	RightSide,
	Button,
	ContactButton
} from './styles'

import InitialVideo from '../../../../assets/videos/initialVideo.mp4'
import { HiChevronRight } from 'react-icons/hi'

const Initial = () => {
	const videoRef = useRef<HTMLVideoElement>(null)

	const scrollToContact = () => {
		const contact = document.getElementById('form-rd')
		contact?.scrollIntoView({ behavior: 'smooth' })
	}

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]: IntersectionObserverEntry[]) => {
				if (!entry.isIntersecting) {
					videoRef.current?.pause()
					console.log('pause')
				} else {
					videoRef.current?.play()
					console.log('play')
				}
			},
			{ threshold: 0.25 }
		)
		if (videoRef.current) {
			observer.observe(videoRef.current)
		}
		return () => observer.disconnect()
	}, [])

	return (
		<Container
			style={{
				display: 'flex',
				justifyContent: 'center',
				marginLeft: '10%',
				marginRight: '10%',
				width: 'auto',
			}}
		>
			<Video
				ref={videoRef}
				src={InitialVideo}
				muted
				autoPlay
				loop
			></Video>

			<LeftSide>
				<Text>
				Transforme ideias em soluções inovadoras através do Design Thinking da DUXCOD3!
				</Text>
				<ContactButton onClick={scrollToContact}>
					Fale com Nosso Especialista!
				</ContactButton>
			</LeftSide>
		</Container>
	)
}

export default Initial
