import companies from "../../../../assets/imagens/Companies.png";
import companies2 from "../../../../assets/imagens/Companies2.png";
import { Subtitle } from "../../../../components/elements/subtitle";
import { ParceirosContainer } from "./styles";

export const Parceiros = () => {
  return (
    <ParceirosContainer>
      <Subtitle color="white">
        <span>PARCEIROS DENTRO E FORA DO BRASIL:</span>
      </Subtitle>
      <img src={companies} alt="companies" className="companies" />
      <img src={companies2} alt="companies2" className="companies2" />
    </ParceirosContainer>
  );
};
