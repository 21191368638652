import styled from "styled-components";
import whatsapp from "../../../assets/imagens/whatsapp.png"

export const FloatButton = styled.div`
  a {
    position: fixed;
    border-radius: 50%;
    right: 30px;
    bottom: 40px;
    z-index: 3 ;
    img {
      width: 90px;
      height: 90px;
      transition: all 300ms ease-out 50ms;
    }
  }

  a:hover {
    img {
      width: 100px;
      height: 100px;
      transition: all 300ms ease-out 50ms;
    }
  }
`
