import React from 'react'
import styled from 'styled-components'
import { fontsType } from '../../../styles/fonts'

interface Props {
	firstText?: string
	pinkText: string
	secondText?: string
}

const MainText = (props: Props) => {
	const { firstText, pinkText, secondText } = props
	return (
		<Container>
			<Text>
				{firstText && firstText}
				<PinkText> {pinkText} </PinkText>
				{secondText && secondText}
			</Text>
		</Container>
	)
}

const Container = styled.div`
	font-family: ${fontsType.ErbaumMedium};
	display: flex;
	flex-wrap: wrap;
	width: 500px;
  margin: 0 auto;
	color: #fff;
  text-align: center;
	align-items: center;
	justify-content: center;

  font-size: x-large;

	@media (max-width: 550px) {
    width: 100%;
  }

	@media (min-width: 768px) {
		font-size: xx-large;
	}
`

const Text = styled.span``

const PinkText = styled.span`
  color: #F653FF;
`

export default MainText
