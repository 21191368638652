import React from 'react'

import { Container, Content, Title, Number, Text } from './styles'

interface CardHowItWorksProps {
	title: string
	text: string
	icon: string
}

const CardHowItWorks = ({ text, title, icon }: CardHowItWorksProps) => {
	return (
		<Container>
			<Content>
				<Number>
					<img src={icon}/>
				</Number>
				<Title>{title}</Title>
				<Text>{text}</Text>
			</Content>
		</Container>
	)
}

export default CardHowItWorks
