import React from 'react'
import MainText from '../../../../components/elements/MainText'

import { Container, Root, Slide } from './styles'

import CarouselImage1 from '../../../../assets/imagens/CarouselImage1.png'
import CarouselImage2 from '../../../../assets/imagens/CarouselImage2.png'
import CarouselImage3 from '../../../../assets/imagens/CarouselImage3.png'
import CarouselImage4 from '../../../../assets/imagens/CarouselImage4.png'
import CarouselImage5 from '../../../../assets/imagens/CarouselImage5.png'
import CarouselImage6 from '../../../../assets/imagens/CarouselImage6.png'

import 'swiper/css'
import 'swiper/css/navigation'

import { Navigation } from 'swiper'

const Process = () => {
	return (
		<Container>
			<MainText
				firstText='Entenda as etapas do'
				pinkText='nosso processo'
			/>

			<Root
				loop={true}
				navigation={true}
				modules={[Navigation]}
			>
				<Slide>
					<img
						src={CarouselImage1}
						alt='CarouselImage1'
					/>
				</Slide>
				<Slide>
					<img
						src={CarouselImage2}
						alt='CarouselImage2'
					/>
				</Slide>
				<Slide>
					<img
						src={CarouselImage3}
						alt='CarouselImage3'
					/>
				</Slide>
				<Slide>
					<img
						src={CarouselImage4}
						alt='CarouselImage4'
					/>
				</Slide>
				<Slide>
					<img
						src={CarouselImage5}
						alt='CarouselImage5'
					/>
				</Slide>
				<Slide>
					<img
						src={CarouselImage6}
						alt='CarouselImage6'
					/>
				</Slide>
			</Root>
		</Container>
	)
}

export default Process
